var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.computedTableOrder.length <= 0
        ? _c("z-select", {
            staticClass: "mb-3",
            attrs: {
              hideDetails: true,
              label: "Deseja ordenar pela tabela de:",
              items: _vm.computedTableOrder,
              itemText: "name",
              "item-value": "value",
              readonly:
                _vm.isEditingMode || _vm.hasOrderChipTemplateAndJourneyProp,
            },
            model: {
              value: _vm.choosedDatas.tableChoosed,
              callback: function ($$v) {
                _vm.$set(_vm.choosedDatas, "tableChoosed", $$v)
              },
              expression: "choosedDatas.tableChoosed",
            },
          })
        : _c("span", [
            _vm._v(
              " Já foi incluido filtros de ordenação para os campos de jornada e templates "
            ),
          ]),
      _vm.choosedDatas.tableChoosed == "user"
        ? _c(
            "div",
            { key: _vm.key, staticClass: "journey" },
            [
              _c("z-select", {
                staticClass: "mb-3",
                attrs: {
                  hideDetails: true,
                  label: "Coluna da tabela",
                  "item-value": "value",
                  items: _vm.usersFields,
                  itemText: "text",
                },
                model: {
                  value: _vm.choosedDatas.tableName,
                  callback: function ($$v) {
                    _vm.$set(_vm.choosedDatas, "tableName", $$v)
                  },
                  expression: "choosedDatas.tableName",
                },
              }),
              _c("z-select", {
                staticClass: "mb-3",
                attrs: {
                  hideDetails: true,
                  label: "Ordem",
                  "item-value": "value",
                  items: _vm.orders,
                  itemText: "text",
                },
                model: {
                  value: _vm.choosedDatas.orderName,
                  callback: function ($$v) {
                    _vm.$set(_vm.choosedDatas, "orderName", $$v)
                  },
                  expression: "choosedDatas.orderName",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.choosedDatas.tableChoosed == "template"
        ? _c(
            "div",
            { key: _vm.key, staticClass: "journey" },
            [
              _c("z-select", {
                staticClass: "mb-3",
                attrs: {
                  hideDetails: true,
                  label: "Coluna da tabela",
                  "item-value": "value",
                  items: _vm.templatesFields,
                  itemText: "text",
                },
                model: {
                  value: _vm.choosedDatas.tableName,
                  callback: function ($$v) {
                    _vm.$set(_vm.choosedDatas, "tableName", $$v)
                  },
                  expression: "choosedDatas.tableName",
                },
              }),
              _c("z-select", {
                staticClass: "mb-3",
                attrs: {
                  hideDetails: true,
                  label: "Ordem",
                  "item-value": "value",
                  items: _vm.orders,
                  itemText: "text",
                },
                model: {
                  value: _vm.choosedDatas.orderName,
                  callback: function ($$v) {
                    _vm.$set(_vm.choosedDatas, "orderName", $$v)
                  },
                  expression: "choosedDatas.orderName",
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }