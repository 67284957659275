<template>
  <div>
    <!-- CRIAR LÓGICA PARA FAZER USUÁRIO ESCOLHER QUAL TIPO DE ORDENAÇÃO ELE QUER
    SE DESEJA ORDENAÇÃO DE TEMPLATES OU ORDENAÇÃO DE JORNADAS
    RENOMEAR COMPONETE ATUAL PARA TER O NOME CORRETO
    VERIFICAR SE ESSA LÓGICA DE ESCOLHER O TIPO DE ORDENAÇÃO NO COMPONENTE
    VAI DE FATO FAZER SENTIDO NO FUNCIONAMENTO GERAL 
    SE FIZER, DEIXAR ESSE COMPONETNE MAIS DINÂMICO PARA PODER RECEBER
    DIFERENTES TIPOS DE TABELAS... -->

    <z-select
      v-if="!computedTableOrder.length <= 0"
      v-model="choosedDatas.tableChoosed"
      :hideDetails="true"
      label="Deseja ordenar pela tabela de:"
      :items="computedTableOrder"
      itemText="name"
      item-value="value"
      :readonly="isEditingMode || hasOrderChipTemplateAndJourneyProp"
      class="mb-3"
    />
    <span v-else>
      Já foi incluido filtros de ordenação para os campos de jornada e templates
    </span>

    <div v-if="choosedDatas.tableChoosed == 'user'" :key="key" class="journey">
      <z-select
        v-model="choosedDatas.tableName"
        :hideDetails="true"
        label="Coluna da tabela"
        item-value="value"
        :items="usersFields"
        itemText="text"
        class="mb-3"
      />

      <z-select
        v-model="choosedDatas.orderName"
        :hideDetails="true"
        label="Ordem"
        item-value="value"
        :items="orders"
        itemText="text"
        class="mb-3"
      />
    </div>

    <div v-if="choosedDatas.tableChoosed == 'template'" :key="key" class="journey">
      <z-select
        v-model="choosedDatas.tableName"
        :hideDetails="true"
        label="Coluna da tabela"
        item-value="value"
        :items="templatesFields"
        itemText="text"
        class="mb-3"
      />

      <z-select
        v-model="choosedDatas.orderName"
        :hideDetails="true"
        label="Ordem"
        item-value="value"
        :items="orders"
        itemText="text"
        class="mb-3"
      />
    </div>
  </div>
</template>

<script>
const _ = require("lodash");
export default {
  inject: ["usersFields", "templatesFields", "orders"],
  data() {
    return {
      key: 1,
      choosedDatas: { orderName: "", tableName: "" },
      tableToOrder: [
        { name: "Template", value: "template" },
        { name: "Usuário", value: "user" },
      ],
      tableChoosed: null,
    };
  },
  created() {
    console.log(this.usersFields, "here usuários");
    if (this.currentEditChipProp) {
      const child = this.currentEditChipProp.childs[0];

      this.choosedDatas.tableChoosed = child.chip_assistant.includes("template")
        ? "template"
        : "user";
    }
  },
  computed: {
    isEditingMode() {
      return this.currentEditChipProp != null;
    },

    alreadyHasComponentWithTypeTemplate() {
      const local_list = this.$store.getters["app/$chipsFilterList"];
      const order_list = local_list.filter((e) => e.chip_type === "order");

      const template_type = this.hasChipWithThisType(order_list, "template");

      return template_type.length > 0;
    },

    alreadyHasComponentWithTypeJourney() {
      const local_list = this.$store.getters["app/$chipsFilterList"];
      const order_list = local_list.filter((e) => e.chip_type === "order");

      const template_type = this.hasChipWithThisType(order_list, "journey");

      return template_type.length > 0;
    },

    computedTableOrder() {
      const infos = _.cloneDeep(this.tableToOrder);

      if (!this.currentEditChipProp) {
        if (this.alreadyHasComponentWithTypeTemplate) {
          const template_idx = infos.findIndex((d) => d.value == "template");

          if (template_idx >= 0) infos.splice(template_idx, 1);
        }

        // if (this.alreadyHasComponentWithTypeJourney) {
        //   const journey_idx = infos.findIndex((d) => d.value == "journey");

        //   if (journey_idx >= 0) infos.splice(journey_idx, 1);
        // }
      }

      return infos;
    },
  },
  props: {
    currentEditChipProp: {
      type: Object,
      default: null,
    },
    hasOrderChipTemplateAndJourneyProp: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    "choosedDatas.tableChoosed"(nv) {
      if (nv) {
        this.choosedDatas.orderName = "";
        this.choosedDatas.tableName = "";
      }
    },

    "choosedDatas.orderName"(nv) {
      if (nv && this.choosedDatas.tableName) {
        const child = {
          id: Math.random().toString(16).slice(2),
          chip_info_child_id: Math.random().toString(16).slice(2),
          chip_child_label: `${this.chooseLabel(this.choosedDatas.tableName)} (${
            this.choosedDatas.orderName == "ASC" ? "Crescente" : "Decrescente"
          } - ${
            this.choosedDatas.tableChoosed == "template" ? "Templates" : "Usuários"
          })`,
          chip_assistant: `${this.choosedDatas.orderName}-${this.choosedDatas.tableName}-${this.choosedDatas.tableChoosed}`,
          chip_text_date: ``,
          chip_start_date: "",
          chip_end_date: "",
        };

        console.log(child);
        this.$emit("select-order", child);

        if (!this.isEditingMode) this.choosedDatas.tableChoosed = "";
        else this.resetColumnAndOrder();
      }
    },

    "choosedDatas.tableName"(nv) {
      if (nv && this.choosedDatas.orderName) {
        const label = `${this.chooseLabel(nv)} (${
          this.choosedDatas.orderName == "ASC" ? "Crescente" : "Decrescente"
        } - ${this.choosedDatas.tableChoosed == "template" ? "Templates" : "Usuários"})`;

        const child = {
          id: Math.random().toString(16).slice(2),
          chip_info_child_id: Math.random().toString(16).slice(2),
          chip_child_label: label,
          chip_assistant: `${this.choosedDatas.orderName}-${this.choosedDatas.tableName}-${this.choosedDatas.tableChoosed}`,
          chip_text_date: ``,
          chip_start_date: "",
          chip_end_date: "",
        };

        console.log(child);

        this.$emit("select-order", child);

        if (!this.isEditingMode) this.choosedDatas.tableChoosed = "";
        else this.resetColumnAndOrder();
      }
    },
  },
  methods: {
    hasChipWithThisType(order_list, type) {
      const template_type = order_list.flatMap((el) =>
        el.childs.filter((child) => child.chip_assistant.includes(type))
      );

      return template_type;
    },
    chooseLabel(nameToFind) {
      let label = "";

      if (this.choosedDatas.tableChoosed == "journey") {
        let info = this.usersFields.find((el) => el.value === nameToFind);
        label = info.text;
      }

      if (this.choosedDatas.tableChoosed == "template") {
        let datas = this.templatesFields.find((e) => e.value == nameToFind);
        label = datas.text;
      }

      return label;
    },
    resetColumnAndOrder() {
      this.choosedDatas.orderName = "";
      this.choosedDatas.tableName = "";
      this.key++;
    },
  },
};
</script>

<style lang="scss" scoped></style>
